import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/sanatera-logo.png';
import { getMaskPhone } from '../../utils/maskPhone';
import style from './index.module.scss';

export default function Header({ landingData, toggleModal }) {
	const [showHeader, setShowHeader] = React.useState(true); // стейт видимости header
	const [scrollPos, setScrollPos] = React.useState(0); // дефолтная позиция скролла
	const offset = -120; // offset, после которого скрывается header

	const phoneMask = getMaskPhone(landingData.phone); // преобразование телефона в красивый вид

	// Отслеживание скролла и запись в стейт
	const handleScroll = () => {
		setScrollPos(document.body.getBoundingClientRect().top);
		setShowHeader(
			document.body.getBoundingClientRect().top > offset ||
				document.body.getBoundingClientRect().top > scrollPos
		);
	};
	// Отслеживание скролла
	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});

	return (
		<div
			className={`${style.header} && ${
				showHeader ? style.headerVisible : style.headerHidden
			}`}
		>
			<div className={style.inner}>
				<Link to='/'>
					<div className={style.container}>
						<img className={style.logo} src={logo} alt='logo' />
					</div>
				</Link>
				<a
					className='mgo-number-29473'
					id={style.iconPhone}
					href={'tel:+' + landingData.phone}
				>
					{' '}
				</a>
				<div className={style.contact} onClick={toggleModal}>
					<div className={style.city}>{landingData.city}</div>
					<div className='mgo-number-29473' id={style.phone}>
						{phoneMask}
					</div>
				</div>
			</div>
		</div>
	);
}
