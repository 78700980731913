import React from 'react';
import style from './index.module.scss';

export default function SliderItem({ dataElement, toggleModalSlider }) {
	return (
		<div
			onClick={() => toggleModalSlider(dataElement)}
			className={style.container}
		>
			<div className={style.imgBox}>
				<img className={style.img} src={dataElement.img} alt='problem_img' />
				<div className={style.titleContainer}>
					<div className={style.title}>{dataElement.title}</div>
				</div>
			</div>
		</div>
	);
}
