import React from 'react';
import style from './index.module.scss';

export default function PricesList({ landingData }) {
	return (
		<div className={style.pricesList}>
			<div className={style.inner}>
				<div className={style.content}>
					<div className={style.titleContainer}>
						<h2 className={style.title}>Стоимость услуг:</h2>
					</div>
					<div className={style.table}>
						{landingData.price_list.map((element, i) => (
							<div key={element.service_name + i} className={style.row}>
								<div className={style.cell1}>
									{element.service_name}{' '}
									<span className={style.subtitle}>
										{element.service_subtitle}
									</span>
								</div>
								<div className={style.cell2}>
									{element.price} руб {element.comment}
								</div>
							</div>
						))}
					</div>

					{landingData.price_list_additional.length && (
						<>
							{' '}
							<div className={style.titleContainer}>
								<h2 className={style.title}>Дополнительные&nbsp;услуги:</h2>
							</div>
							<div className={style.table}>
								{landingData.price_list_additional.map((element, i) => (
									<div key={element.service_name + i} className={style.row}>
										<div className={style.cell1}>
											{element.service_name}{' '}
											<span className={style.subtitle}>
												{element.service_subtitle}
											</span>
										</div>
										<div className={style.cell2}>
											{element.price} руб {element.comment}
										</div>
									</div>
								))}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
