import React from 'react';
import style from '../Features/index.module.scss';
import img_1 from '../../assets/images/features/feature5.svg';
import img_2 from '../../assets/images/features/feature17.svg';
import img_3 from '../../assets/images/features/feature18.svg';

export default function Features() {
	return (
		<div className={style.features}>
			<div className={style.inner}>
				<div className={style.listItems}>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_1} alt='feature' />
						</div>
						<p className={style.title}>Опытные подологи</p>
						<p className={style.subtitle}>Вас лечат врачи с опытом от 9 лет</p>
					</div>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_2} alt='feature' />
						</div>
						<p className={style.title}>Быстрый результат</p>
						<p className={style.subtitle}>Улучшение после одной процедуры</p>
					</div>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_3} alt='feature' />
						</div>
						<p className={style.title}>Гарантия 100%</p>
						<p className={style.subtitle}>Вернем деньги, если что-то не понравится</p>
					</div>
				</div>
			</div>
		</div>
	);
}
