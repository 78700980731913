import React from 'react';
import style from './index.module.scss';

export default function ModalSlider({
	dataModal,
	toggleModalSlider,
	toggleModal,
}) {
	return (
		<>
			<div className={style.background} onClick={toggleModalSlider}></div>
			<div className={style.container}>
				<div className={style.modal}>
					<div className={style.form}>
						<div className={style.close} onClick={toggleModalSlider}>
							×
						</div>
						<div className={style.title}>{dataModal.title}</div>
						<div className={style.description}>{dataModal.description}</div>
						<button onClick={toggleModal} className={style.button}>
							Записаться
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
