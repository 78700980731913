import sertificate1 from '../../assets/images/sertificates/sertificate1.jpg';
import sertificate2 from '../../assets/images/sertificates/sertificate2.jpg';
import sertificate3 from '../../assets/images/sertificates/sertificate3.jpg';
import sertificate4 from '../../assets/images/sertificates/sertificate4.jpg';

export const images = [
	{
		id: 1,
		img: sertificate1,
		label: 'Патент на изобретение',
	},
	{
		id: 2,
		img: sertificate3,
		label: 'Сертификат соответствия',
	},
	{
		id: 3,
		img: sertificate2,
		label: 'Мед. лицензия',
	},
	{
		id: 4,
		img: sertificate4,
		label: 'СанЭпидем заключение',
	}
];
