/* eslint-disable no-undef */
import React from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import ModalPolicy from '../ModalPolicy/ModalPolicy.jsx';
import { getUnMaskPhone } from '../../utils/maskPhone';
import style from './index.module.scss';

export default function ModalBackCall({ toggleModal, landingData }) {
	let yandex;

	try {
		yandex = landingData?.metrika[0]?.yandex;
	} catch {
		yandex = '';
	}

	const navigation = useNavigate();
	const [showModalPolicy, setShowModalPolicy] = React.useState(false); // стейт модального окна политики конфиденциальности

	const [checkbox, setCheckbox] = React.useState(true); // стейт чекбокса

	// получаем и записываем в стейт данные клиента
	const handleUser = event => {
		event.preventDefault();
		const nameClient = event.target.name.value;
		const phone = event.target.phone.value;
		const newPhone = getUnMaskPhone(phone);

		if (checkbox && phone) {
			let newUserData = {
				name: 'Клиент: ' + nameClient,
				phone: newPhone,
				department: landingData.department,
				status: 1,
				notes: '',
				city: landingData.city,
				email: '',
				url: window.location.href,
			};

			fetch(
				'https://onshorkin.com/i8fd90sd/v09cxvjksdf/xzs9/sda9/service_request/',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify(newUserData),
				}
			)
				.then(response => response.json())
				.then(data => {
					console.log(data);
					ym(yandex, 'reachGoal', 'SendingCallbackForm');
					_tmr.push({
						type: 'reachGoal',
						id: 3314607,
						value: 900,
						goal: 'otpravkazayavki',
					});
				})
				.catch(e => {
					console.log(e);
					navigation('/error');
				});
			navigation('/sent');
		}
	};

	// переключение модального окна
	const toggleModalPolicy = () => {
		setShowModalPolicy(!showModalPolicy);
	};
	// переключение чекбокса
	const switchCheck = () => {
		setCheckbox(!checkbox);
	};

	return (
		<>
			{showModalPolicy && (
				<ModalPolicy
					landingData={landingData}
					toggleModalPolicy={toggleModalPolicy}
				/>
			)}
			<div className={style.background} onClick={toggleModal}></div>
			<div className={style.container}>
				<div className={style.modal}>
					<form onSubmit={handleUser} className={style.form} action='#'>
						<div className={style.close} onClick={toggleModal}>
							×
						</div>

						<label className={style.formElement} htmlFor='name'>
							<input
								className={style.input}
								type='text'
								name='name'
								id='name'
								placeholder='Ваше имя'
							/>
						</label>

						<label className={style.formElement} htmlFor='phone'>
							<InputMask
								className={style.input}
								mask='+7 (999) 999-99-99'
								type='tel'
								name='phone'
								id='phone'
								placeholder='Ваш номер телефона'
								required
							/>
						</label>

						<button className={style.button} variant='outlined' type='submit'>
							Отправить
						</button>

						<div onChange={switchCheck} className={style.check}>
							<div
								className={
									checkbox ? style.checkboxStyleActive : style.checkboxStyle
								}
							></div>
							<input
								type='checkbox'
								name='checkbox'
								id='checkbox'
								defaultChecked
								required
								className={style.checkbox}
							/>
							<label className={style.text} htmlFor='checkbox'>
								Подтверждаю, что я ознакомлен с{' '}
								<span onClick={toggleModalPolicy} className={style.link}>
									политикой конфиденциальности
								</span>{' '}
								и даю свое согласие на сбор и обработку персональных данных
							</label>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}
